export const LINKED_COMMENT_QUERY_PARAM = 'lc';

export const SORT_COMMENTS_NEW = 'new';
export const SORT_COMMENTS_BEST = 'best';
export const SORT_COMMENTS_CONTROVERSIAL = 'controversial';

export const BLOCK_LEVEL = {
  SELF: 'self',
  MODERATOR: 'moderator',
  ADMIN: 'admin',
};
