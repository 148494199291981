export const CONFIRM_FILE_REMOVE = 'confirm_file_remove';
export const CONFIRM_EXTERNAL_RESOURCE = 'confirm_external_resource';
export const COMMENT_ACKNOWEDGEMENT = 'comment_acknowlegement';
export const INCOMPATIBLE_DAEMON = 'incompatible_daemon';
export const FILE_TIMEOUT = 'file_timeout';
export const FILE_SELECTION = 'file_selection';
export const DOWNLOADING = 'downloading';
export const AUTO_GENERATE_THUMBNAIL = 'auto_generate_thumbnail';
export const AUTO_UPDATE_DOWNLOADED = 'auto_update_downloaded';
export const ERROR = 'error';
export const UPGRADE = 'upgrade';
export const EMAIL_COLLECTION = 'email_collection';
export const PHONE_COLLECTION = 'phone_collection';
export const FIRST_REWARD = 'first_reward';
export const AUTHENTICATION_FAILURE = 'auth_failure';
export const TRANSACTION_FAILED = 'transaction_failed';
export const REWARD_GENERATED_CODE = 'reward_generated_code';
export const AFFIRM_PURCHASE = 'affirm_purchase';
export const CONFIRM_CLAIM_REVOKE = 'confirm_claim_revoke';
export const FIRST_SUBSCRIPTION = 'firstSubscription';
export const SEND_TIP = 'send_tip';
export const CONFIRM_SEND_TIP = 'confirm_send_tip';
export const SOCIAL_SHARE = 'social_share';
export const PUBLISH = 'publish';
export const PUBLISH_PREVIEW = 'publish_preview';
export const SEARCH = 'search';
export const CONFIRM_TRANSACTION = 'confirm_transaction';
export const CONFIRM_THUMBNAIL_UPLOAD = 'confirm_thumbnail_upload';
export const WALLET_ENCRYPT = 'wallet_encrypt';
export const WALLET_DECRYPT = 'wallet_decrypt';
export const WALLET_UNLOCK = 'wallet_unlock';
export const WALLET_SYNC = 'wallet_sync';
export const WALLET_PASSWORD_UNSAVE = 'wallet_password_unsave';
export const CREATE_CHANNEL = 'create_channel';
export const YOUTUBE_WELCOME = 'youtube_welcome';
export const SET_REFERRER = 'set_referrer';
export const SIGN_OUT = 'sign_out';
export const LIQUIDATE_SUPPORTS = 'liquidate_supports';
export const MASS_TIP_UNLOCK = 'mass_tip_unlock';
export const CONFIRM_AGE = 'confirm_age';
export const SYNC_ENABLE = 'SYNC_ENABLE';
export const IMAGE_UPLOAD = 'image_upload';
export const MOBILE_SEARCH = 'mobile_search';
export const VIEW_IMAGE = 'view_image';
export const CONFIRM_REMOVE_BTC_SWAP_ADDRESS = 'confirm_remove_btc_swap_address';
export const COLLECTION_ADD = 'collection_add';
export const COLLECTION_DELETE = 'collection_delete';
